
import {Component, Vue} from "vue-property-decorator";
import TamamlananGorevList from "@/components/lists/TamamlananGorevList.vue";
import DevamEdenGorevList from "@/components/lists/DevamEdenGorevList.vue";
import TakipSenkronize from "@/plugins/uyap-plugin/takip_ac/TakipSenkronize";
import app from '@/main';

@Component({
  components:{DevamEdenGorevList, TamamlananGorevList}
})
export default class IslemlerView extends Vue {
  takipSenkronize : TakipSenkronize = new TakipSenkronize();
  loading : boolean = false;
  esas_no : string = "";
  async senkronize(){
    try{
      this.loading = true;
      await this.takipSenkronize.runQueryLastOneWeek();
      app.$toast.success("Takip Senkronizasyonu başarılı. Senkronize edilen takip sayısı : " + this.takipSenkronize.queueCount);
    }catch (e) {
      app.$toast.error("Takip Senkronizasyonu yapılamadı. Hata : " + e.message)
    }finally {
      this.loading = false;
    }

  }

  async uyariSenkronize(){
    try {
      let res =  await this.takipSenkronize.runQueryForSelectedItems([{
        dosya_esas_no : this.esas_no,
        birim_adi : ""
      }]);
      console.log("res");
    }catch (e) {
      console.log("eee",e);
    }
  }

  mounted() {
    this.load()
  }

  load() {
    if (!this.$store.getters.isImzaConnected)
      this.$store.dispatch('updateTasks');
  }
}
